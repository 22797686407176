import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { EFreightType, EProductCategories, EProductLocations } from "../types"
import { CartItemModel } from "./cart-item" // do not import from index - cart.ts is exported BEFORE cart-item.ts due to prettier so the model won't be loaded
import * as R from "ramda"
import { priceStringToInteger } from "../utils"

export const CartModel = types
  .model("CartModel")
  .props({
    id: types.maybeNull(types.number),
    totalTax: types.maybeNull(types.number),
    subtotal: types.maybeNull(types.number),
    total: types.maybeNull(types.number),
    cartItems: types.array(CartItemModel),
    orderNotes: types.maybeNull(types.string),
    externalDocumentNumber: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get estimatedSubtotal(): number {
      return self.cartItems.reduce((acc, cur) => acc + cur.lineAmount, 0)
    },
    get frozenMinimum(): number | undefined {
      return self.rootStore.userStore.currentUser.erpCustomer?.frozenMinimum
    },
    get surreyMinimum(): number | undefined {
      return self.rootStore.userStore.currentUser.erpCustomer?.surreyMinimum
    },
    get freightType(): EFreightType {
      return R.all((ci) => ci.product.productCategory === EProductCategories.supplies, self.cartItems)
        ? EFreightType.packaged
        : EFreightType.palletized
    },

    get unitTotalCount(): number {
      return self.cartItems.reduce((partialSum, cartItem) => partialSum + cartItem.quantity, 0) || 0
    },
    get frozenItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.productCategory === EProductCategories.frozen)
        .reduce((acc, cur) => (acc += cur.lineAmount), 0)
    },
    get foodItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.productCategory === EProductCategories.food)
        .reduce((acc, cur) => (acc += cur.lineAmount), 0)
    },
    get supplyItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.productCategory === EProductCategories.supplies)
        .reduce((acc, cur) => (acc += cur.lineAmount), 0)
    },
    get nonSurreySupplyItemSum(): number {
      return self.cartItems
        .filter(
          (ci) =>
            ci.product.productCategory === EProductCategories.supplies &&
            ci.product.itemLocation != EProductLocations.surrey,
        )
        .reduce((acc, cur) => (acc += cur.lineAmount), 0)
    },
    get surreyItemSum(): number {
      return self.cartItems
        .filter((ci) => ci.product.itemLocation === EProductLocations.surrey)
        .reduce((acc, cur) => (acc += cur.lineAmount), 0)
    },
  }))
  .views((self) => ({
    get hasSurreyItems(): boolean {
      return self.cartItems?.some((ci) => ci.product.productCategory === EProductCategories.supplies)
    },
    get hasFrozenItems(): boolean {
      return self.cartItems?.some((ci) => ci.product.productCategory === EProductCategories.frozen)
    },
    get hasNonFrozenItems(): boolean {
      return self.cartItems?.some((ci) => ci.product.productCategory !== EProductCategories.frozen)
    },
  }))
  .views((self) => ({
    get itemSum(): number {
      return self.foodItemSum + self.frozenItemSum + self.supplyItemSum
    },
    get palletizedItemSum(): number {
      return (
        self.foodItemSum +
        self.nonSurreySupplyItemSum +
        (!self.frozenMinimum || self.frozenItemSum >= self.frozenMinimum ? self.frozenItemSum : 0) +
        (!self.surreyMinimum || self.surreyItemSum >= self.surreyMinimum ? self.surreyItemSum : 0)
      )
    },
  }))
  .views((self) => ({
    get freeShippingDeficit(): number {
      const { currentUser } = self.rootStore.userStore
      switch (self.freightType) {
        case EFreightType.packaged:
          return currentUser.erpCustomer.packagedFreightMinimum - self.itemSum
        case EFreightType.palletized:
          return currentUser.erpCustomer.palletizedFreightMinimum - self.palletizedItemSum
      }
    },
    get pickupDeficit(): number {
      return self.rootStore.userStore.currentUser.erpCustomer?.localPickupMinimum - self.itemSum
    },
    get frozenItemsDeficit(): number {
      if (self.freightType == EFreightType.packaged) return 0
      return self.frozenMinimum - self.frozenItemSum
    },
    get surreyItemsDeficit(): number {
      if (self.freightType == EFreightType.packaged) return 0
      return self.surreyMinimum - self.surreyItemSum
    },
    get regularPriceSubtotal(): number {
      return self.cartItems.reduce((acc, cur) => acc + cur.quantity * cur.product.unitPrice, 0)
    },
  }))
  .views((self) => ({
    get totalDiscounts(): number {
      return self.regularPriceSubtotal - self.subtotal
    },
    get qualifiesForFreeShipping(): boolean {
      return self.freeShippingDeficit <= 0
    },
    get pickupMinimumMet(): boolean {
      return self.pickupDeficit <= 0
    },
    get surreyMinimumMet(): boolean {
      return self.surreyItemsDeficit <= 0
    },
    get frozenMinimumMet(): boolean {
      return self.frozenItemsDeficit <= 0
    },
    get isPriced(): boolean {
      return self.subtotal >= 0
    },
  }))
  .actions((self) => ({
    setOrderNotes(notes: string) {
      self.orderNotes = notes
    },
    setExternalDocumentNumber(edn: string) {
      self.externalDocumentNumber = edn
    },
  }))
  .preProcessSnapshot((snapshot) => {
    if (!snapshot) return snapshot

    // Pre-processor to handle any left over data that still uses strong prices, etc
    return {
      ...snapshot,
      totalTax:
        snapshot.totalTax != null && typeof snapshot.totalTax === "string"
          ? priceStringToInteger(snapshot.totalTax)
          : snapshot.totalTax,
      subtotal:
        snapshot.subtotal != null && typeof snapshot.subtotal === "string"
          ? priceStringToInteger(snapshot.subtotal)
          : snapshot.subtotal,
      total:
        snapshot.total != null && typeof snapshot.total === "string"
          ? priceStringToInteger(snapshot.total)
          : snapshot.total,
    }
  })

export interface ICart extends Instance<typeof CartModel> {}
